import './ViewProfile.scss';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Dropdown,
  Layout,
  Menu,
  message,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
  Rate,
  Divider,
  Popover,
  Modal,
  Card,
} from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import SayHelloPopup from '../Home/SayHelloPopup/SayHelloPopup';
import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import Ribbon from 'antd/lib/badge/Ribbon';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import SharePageEmailPopup from '../Home/SharePageEmailPopup/SharePageEmailPopup';
import { FaCopy } from '@react-icons/all-files/fa/FaCopy';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { getUniversityResourceSelector } from '../../../MainScreen/MainScreenSlice';
import { fetchBuddyDetailsById, getBuddyViewSelector } from './ViewProfileSlice';
import { useDispatch } from 'react-redux';
import iconRate from '../../../assets/images/icon-rate.svg';
import { FiSend } from '@react-icons/all-files/fi/FiSend';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { AiFillPlayCircle, AiFillTwitterCircle } from 'react-icons/ai';
import { BsFillPlayCircleFill, BsShareFill } from 'react-icons/bs';

import CopyToClipboard from 'react-copy-to-clipboard';
import defaultCoverPic from '../../../assets/images/cover-photo-large.jpeg';
import MessageSuccessPopup from '../Home/SayHelloPopup/MessageSuccessPopup';
import moment from 'moment';
import topRatedBadgePic from '../../../assets//images/badges/top-rated.svg';
import creatorBadgePic from '../../../assets//images/badges/creator.svg';
import influencerBadgePic from '../../../assets//images/badges/influencer.svg';
import courseCompletedBadgePic from '../../../assets//images/badges/course-completed.svg';
import expertBadgePic from '../../../assets//images/badges/expert.svg';
import { domainValidation, getReplyTime } from '../../common/utils';
import { getHost } from '../../../utils/hostDataFunction';
import { FaPaperPlane } from 'react-icons/fa';
import { VideoPreviewModal } from '../../common/VideoPreviewModal/VideoPreviewModal';
import { isMobile } from 'react-device-detect';
import {
  fetchPersonPostsResourceById,
  getPersonPostsSelector,
  clearPersonPostsResourceRespose,
} from './ViewProfilePostsSlice';
import { calculateTimeAgo } from '../StudentLife/common';
import { ProfileDescription } from './ProfileDescription';
import { minutesFormatter } from '../../../utils/fomatter';
import PageLoader from '../../PageLoader';

const { Header, Content, Footer, Sider } = Layout;

const { TabPane } = Tabs;
const { Meta } = Card;
const { Paragraph } = Typography;

const info = () => {
  message.info('Content copied to clipboard');
};

const { Title } = Typography;

function callback(key: any) {
  console.log(key);
}

interface BadgeItemProp {
  badgeTypeId: string;
  badgeUrl: string;
  badgeName: string;
}

function ViewProfile({ selectedId, enableWordPress }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  let dispatch = useDispatch();
  const [sayhelloVisible, setSayhelloVisible] = useState<boolean>(false);
  const [isMessageSuccessModalVisible, setIsMessageSuccessModalVisible] = useState<boolean>(false);
  // console.log('=== selectedId ===', selectedId, id);

  const { payload } = useAppSelector(getUniversityResourceSelector);
  const { buddyView, buddyViewFetchStatus, isFetchingBuddyView } = useAppSelector(getBuddyViewSelector);
  const { personPostsStatus, PersonPostResponse } = useAppSelector(getPersonPostsSelector);

  const [badgeList, setBadgeList] = useState<any>([]);
  const [badgeListLength, setBadgeListLength] = useState<number>(0);

  const [visibleVideModal, setVisibleVideoModal] = useState<boolean>(false);
  const [structureProgrammes, setStructuredProgrammes] = useState([]);

  const dynamicThemeColor = payload?.theme?.themeColor;

  useEffect(() => {
    if (dynamicThemeColor) {
      document.documentElement.style.setProperty('--theme-color', dynamicThemeColor);
    }
  }, [dynamicThemeColor]);

  // console.log("=== PersonPostResponse ===", PersonPostResponse);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const uniId = getHost();

    if (selectedId) {
      // console.log('came to selected');

      dispatch(
        fetchBuddyDetailsById({
          id: selectedId || '',
          universityId: uniId ? uniId : '',
        })
      );
      dispatch(fetchPersonPostsResourceById(selectedId));
    } else if (id) {
      dispatch(
        fetchBuddyDetailsById({
          id: id || '',
          universityId: uniId ? uniId : '',
        })
      );
      dispatch(fetchPersonPostsResourceById(id));
    }
  }, []);

  useEffect(() => {
    if (payload && payload?.programmes && payload?.programmes?.length > 0) {
      const programmeArr = handleProgrammesForMultiSelect(payload?.programmes);
      setStructuredProgrammes(programmeArr);
    }
  }, [payload]);

  useEffect(() => {
    if (buddyView.badges) {
      let length: number = buddyView.badges.length;

      setBadgeListLength(length);
      setBadgeList(buddyView.badges);
    }
  }, [buddyView.badges]);

  // Clearning the posts state
  useEffect(() => {
    dispatch(clearPersonPostsResourceRespose());

    const uniId = getHost();
    if (!selectedId) {
      dispatch(fetchBuddyDetailsById({ id: id || '', universityId: uniId ? uniId : '' }));
      dispatch(fetchPersonPostsResourceById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (sayhelloVisible || visibleVideModal) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      // console.log('==Overflow hidden==');
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      // console.log('==Overflow removed==');
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
    };
  }, [sayhelloVisible, visibleVideModal]);

  const { t } = useTranslation();

  const menu = (
    <div className="shareDropdownLayout">
      <Menu className="shareDropdown">
        <div>
          <Menu.Item key="0" style={{ marginBottom: '3px' }}>
            <div className="FacebookShareButtonLayout">
              <FacebookShareButton className="dropDownShareButtonFb" url={buddyView.profileLink as string}>
                <FaFacebookSquare className="facebookIcon" size={18} />
                <div className="dropDownShareButtonText">Facebook</div>
              </FacebookShareButton>
            </div>
          </Menu.Item>

          <Menu.Item key="2" style={{ marginBottom: '5px' }}>
            <TwitterShareButton className="dropDownShareButtonFb" url={buddyView.profileLink as string}>
              <AiFillTwitterCircle className="twitterIcon" size={18} />
              <div className="dropDownShareButtonText">Twitter</div>
            </TwitterShareButton>
          </Menu.Item>

          {/* <Menu.Item key="3" style={{ marginBottom: "8px" }}>
            <SharePageEmailPopup type="profile" />
          </Menu.Item> */}

          <Menu.Item key="4">
            <CopyToClipboard text={buddyView.profileLink as string} onCopy={info}>
              <button className="dropDownShareButtonCopyUrl" onClick={() => info}>
                <FaCopy className="copyUrlIcon" size={16} />
                <div className="dropDownShareButtonText">Copy Url</div>
              </button>
            </CopyToClipboard>
          </Menu.Item>
        </div>
      </Menu>
    </div>
  );

  const renderRatings = (ratings: any) => {
    return (
      <>
        {ratings &&
          ratings?.map((item: any, key: any) => {
            return (
              <>
                <div key={key}>
                  <div>
                    {item?.prospectName} {item?.ratings}/5 <Rate defaultValue={item?.ratings} disabled={true} />{' '}
                    <span style={{ float: 'right' }}>{moment(item?.createdDate).format('MMM Do, YYYY')}</span>
                  </div>
                  <div>{item?.comment}</div>
                </div>
                <Divider />
              </>
            );
          })}
      </>
    );
  };

  const handleVideoModal = () => {
    setVisibleVideoModal(true);
  };

  const handleVideoModalCancel = () => {
    setVisibleVideoModal(false);
  };

  const visibleMessagePopup = () => {
    setSayhelloVisible(true);
    setVisibleVideoModal(false);
  };

  const renderWrappedTopic = (description: any) => {
    // Remove HTML tags
    let plainText = description.replace(/<[^>]*>/g, '');
    let words = plainText.split(' ').slice(0, 15).join(' ');
    return words;
  };

  const handleCardOnClick = (postData: any) => {
    navigate('/postDetail', {
      state: {
        data: postData,
        profileDetails: { id: id ?? selectedId, viaViewProfile: true },
      },
    });
  };

  const handleProgrammesForMultiSelect = (programmes: any) => {
    let programmesArr: any = [];
    let courseArr: any = [];
    for (let index = 0; index < programmes.length; index++) {
      const element = programmes[index];

      for (let j = 0; j < element?.courses.length; j++) {
        const courseElement = element?.courses[j];
        let courseObj = {
          label: courseElement?.displayName ?? courseElement?.name,
          value: courseElement?.id,
        };
        courseArr[courseArr.length] = courseObj;
      }

      // let obj = {
      //   label: element?.displayName,
      //   value: element?.id,
      // };
      // programmesArr[programmesArr.length] = obj;
    }
    // console.log("=== courseArr ===", courseArr);

    return courseArr;
  };

  return (
    <>
      <MessageSuccessPopup
        isModalVisible={isMessageSuccessModalVisible}
        setIsModalVisible={setIsMessageSuccessModalVisible}
      />
      {buddyViewFetchStatus === 'success' && (
        <SayHelloPopup
          buddies={buddyView}
          visible={sayhelloVisible}
          setVisible={(isVisible, isSuccess) => {
            setSayhelloVisible(isVisible);
            setIsMessageSuccessModalVisible(isSuccess);
          }}
          id={parseInt(id || selectedId || '0')}
          structureProgrammes={structureProgrammes}
        />
      )}

      <VideoPreviewModal
        visibleVideModal={visibleVideModal}
        handleVideoModalCancel={handleVideoModalCancel}
        firstName={buddyView?.profile?.firstName}
        programme={buddyView?.profile?.programme}
        smallProfileUrl={buddyView?.profile?.smallProfileUrl}
        introVideoUrl={buddyView?.profile?.introVideoUrl}
        themeColor={payload?.theme?.themeColor}
        visibleMessagePopup={visibleMessagePopup}
      />

      {isFetchingBuddyView ? (
        <PageLoader />
      ) : (
        <Content className="viewProfile">
          <Row>
            {!selectedId ? (
              <Tooltip title="Back">
                <Button
                  onClick={() => navigate(-1)}
                  className="backButton"
                  shape="circle"
                  icon={<BiArrowBack />}
                  size="large"
                />
              </Tooltip>
            ) : (
              <></>
            )}

            {/* <img
              className="backroundCover "
              src={buddyView?.profile?.banner?.imageUrl}
              alt="backroundCover"
            /> */}
            <div className="bannerWrapper">
              <div className="bannerImgWrapper">
                <img
                  src={buddyView?.profile?.banner?.imageUrl || defaultCoverPic}
                  alt={buddyView?.profile?.firstName && buddyView?.profile?.lastName + "'s cover image"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultCoverPic;
                  }}
                  className="backroundCover"
                />
              </div>
              <Row className="avatarRow">
                <Avatar className="profileImage" icon={<UserOutlined />} src={buddyView?.profile?.smallProfileUrl} />
                <div className="profileDetails">
                  <div className="profileName">
                    {buddyView?.profile?.displayName}
                    {/* <Button className="shareButtonMobile" ghost>
                      <FaShareAlt className="shareButtonMobileIcon" size={20} />
                    </Button> */}
                  </div>
                  <Ribbon className="profileBadge" text={buddyView?.profile?.type}></Ribbon>
                  {isMobile && buddyView?.profile?.introVideoUrl ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '2px',
                        height: '16px',
                        border: '1px solid gray',
                        borderRadius: '5px',
                        width: '50px',
                        zIndex: 3,
                      }}
                      onClick={handleVideoModal}
                    >
                      <BsFillPlayCircleFill
                        size={12}
                        // onClick={handleVideoModal}
                      />
                    </div>
                  ) : (
                    // <Button onClick={handleVideoModal}>Click</Button>
                    <>
                      {buddyView?.profile?.introVideoUrl ? (
                        <Button
                          className="playButton"
                          size="middle"
                          icon={<BsFillPlayCircleFill style={{ marginRight: '8px' }} />}
                          // shape="default"
                          onClick={handleVideoModal}
                        >
                          Play Video
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </Row>
            </div>
          </Row>

          <div className="profileTab">
            <Row>
              <Col span={24}>
                <Tabs
                  className="tabArea"
                  defaultActiveKey="1"
                  onChange={callback}
                  size={'large'}
                  tabBarExtraContent={
                    <div className="btnArea">
                      <div className="profileEarnedBadges">
                        {/* {badgeList.map((item: BadgeItemProp, i: number) => {
                          if (i < 3) {
                            return <img className="" alt="User-badge" src={item.badgeUrl} />;
                          }
                        })}
                        {badgeListLength > 3 && (
                          <Popover
                            content={
                              <div className="badgesPopover">
                                {badgeList.map((item: BadgeItemProp, i: number) => {
                                  return (
                                    <div className="badgesPopoverBadge">
                                      <img className="" alt="User-badge" src={item.badgeUrl} />
                                      <p>{item.badgeName}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            }
                          >
                            <div className="moreBtn">{badgeListLength - 3}+</div>
                          </Popover>
                        )} */}
                      </div>
                      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <div
                          className="dropDownProfileButton "
                          style={{
                            borderColor: `${payload?.theme?.themeColor}`,
                          }}
                        >
                          <a
                            className="btnShareProfile"
                            href="Share button"
                            onClick={(e) => e.preventDefault()}
                            style={{
                              color: `${payload?.theme?.themeColor}`,
                              borderColor: `${payload?.theme?.themeColor}`,
                            }}
                          >
                            <BsShareFill />
                            <span className="dropDownIcon">
                              <span>{t('ViewProfile.btnShareDropdown')}</span>
                            </span>

                            <DownOutlined />
                          </a>
                        </div>
                      </Dropdown>
                      <Button
                        className="sendMsgBtn"
                        type="primary"
                        onClick={() => setSayhelloVisible(true)}
                        icon={<FiSend />}
                        size="large"
                        style={{
                          backgroundColor: `${payload?.theme?.themeColor}`,
                          borderColor: `${payload?.theme?.themeColor}`,
                        }}
                      >
                        {t('ViewProfile.btnSendMessage')}
                      </Button>
                    </div>
                  }
                >
                  <TabPane tab={t('ViewProfile.profileTabText')} key="1">
                    <Row gutter={20}>
                      <Col xs={24} lg={12} className="mt-lg">
                        <Title className="tabSubTopic">Profile</Title>
                        <div className="LeftCol">
                          <div className="ratedColumn">
                            {buddyView?.helpProspectCount > 0 && buddyView?.replyTime?.count > 0 ? (
                              <div className="flexRow">
                                {buddyView?.avgResponseTimeInMinutes && (
                                  <div className="buddyReplyTime">
                                    {t('ViewProfile.buddyReplyTime')}{' '}
                                    {minutesFormatter(buddyView?.avgResponseTimeInMinutes)}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="buddyProfileTable">
                            <div className="tableInner">
                              {buddyView?.profile ? <ProfileDescription buddyView={buddyView} /> : <></>}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={12} className="mt-lg">
                        {domainValidation(payload) && PersonPostResponse && PersonPostResponse?.length > 0 ? (
                          <>
                            <Title className="tabSubTopic">Posts</Title>
                            <div className="rightCol">
                              <div className="cardContainer">
                                {PersonPostResponse &&
                                  PersonPostResponse?.length > 0 &&
                                  PersonPostResponse.map((item: any, key: any) => {
                                    return (
                                      <>
                                        {item?.media?.length > 0 && item?.media[0]?.url && (
                                          <Card
                                            key={key}
                                            className="card card-iframe"
                                            style={{
                                              backgroundImage: `url(${item?.media[0]?.url})`,
                                              backgroundSize: 'cover',
                                            }}
                                            onClick={() => handleCardOnClick(item)}
                                          >
                                            {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
                                            <div className="cardData">
                                              <Paragraph
                                                ellipsis={{ rows: 1 }}
                                                style={{
                                                  color: '#fff',
                                                  overflow: 'hidden',
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  maxWidth: '100%',
                                                  fontSize: '16px',
                                                  marginBottom: '8px',
                                                }}
                                              >
                                                {item?.textBody ? <>{renderWrappedTopic(item?.textBody)} ...</> : <></>}
                                              </Paragraph>
                                              <div
                                                style={{
                                                  fontWeight: 'bold',
                                                  marginLeft: 'auto',
                                                }}
                                              >
                                                {calculateTimeAgo(item?.created)} | {item?.likeCount ?? 0} Likes
                                              </div>
                                            </div>
                                          </Card>
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Button
                      className="sendMsgBtnSp btn-lg"
                      type="primary"
                      onClick={() => {
                        setSayhelloVisible(true);
                      }}
                      icon={<FiSend />}
                      style={{
                        backgroundColor: `${payload?.theme?.themeColor}`,
                        borderColor: `${payload?.theme?.themeColor}`,
                      }}
                    >
                      {/* {t("ViewProfile.btnSendMessage")} */}Say Hello
                    </Button>
                  </TabPane>
                  {buddyView?.ratings && buddyView?.ratings?.length > 0 ? (
                    <TabPane tab={t('ViewProfile.ratingsTabText')} key="2">
                      <Row gutter={16}>
                        <Col xs={24} lg={12} className="mt-lg">
                          <Title className="tabSubTopic">Ratings</Title>
                          <div className="LeftCol">
                            {/* <Col xs={24} lg={12} className="mt-lg"> */}
                            {/* <Title level={5}>Rating</Title> */}
                            {/* <div className="rightCol"> */}
                            <div className="ratedColumn">
                              <div className="rateTitle">
                                Overall Rating {buddyView?.profile?.rating}/5{' '}
                                <Rate
                                  defaultValue={buddyView?.profile?.rating ? parseInt(buddyView?.profile?.rating) : 0}
                                  disabled
                                />
                              </div>
                              <Divider />
                              <div className="allRatings">
                                {buddyView?.ratings && buddyView?.ratings?.length > 0 ? (
                                  <>{renderRatings(buddyView?.ratings)}</>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            {/* </div> */}
                            {/* </Col> */}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  ) : (
                    <></>
                  )}
                </Tabs>
              </Col>

              {/* <Col className="leftColumnBackround">
                <div className="leftColumn">
                  <div className="leftColumnWidget">
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <div
                        className="dropDownProfileButton "
                        style={{ borderColor: `${payload?.theme?.themeColor}` }}
                      >
                        <a
                          className="btnShareProfile"
                          href="Share button"
                          onClick={(e) => e.preventDefault()}
                          style={{
                            color: `${payload?.theme?.themeColor}`,
                            borderColor: `${payload?.theme?.themeColor}`,
                          }}
                        >
                          <FaShareAlt size={20} style={{ paddingRight: "5px" }} />
                          <span className="dropDownIcon">
                            {t("ViewProfile.btnShareDropdown")}
                          </span>

                          <DownOutlined />
                        </a>
                      </div>
                    </Dropdown>
                    <span className="sendMessageButtonViewProfile">
                      <Button
                        className="sendMessageButtonViePro sendMessageButtonMobile"
                        type="primary"
                        onClick={() => setSayhelloVisible(true)}
                        icon={<FiSend />}
                        size="large"
                        style={{
                          backgroundColor: `${payload?.theme?.themeColor}`,
                          borderColor: `${payload?.theme?.themeColor}`,
                        }}
                      >
                        <div className="sendMessageButtonText">
                          {t("ViewProfile.btnSendMessage")}
                        </div>
                      </Button>
                    </span>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </Content>
      )}
    </>
  );
}

export default ViewProfile;
