import { Card, Col, Input, Row, Select, Avatar, Divider, Button, Typography, Layout, message, Empty } from 'antd';
import SampleWallPaper from '../../../assets/images/sample-wall-paper.png';
import { MainPostCard } from './MainPostCard/MainPostCard';
import { PopularPostCard } from './PopularPostCard/PopularPostCard';
import './StudentLife.scss';
import { AiOutlineLike, AiOutlineShareAlt } from 'react-icons/ai';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { fetchUniversityResourceById, getUniversityResourceSelector } from '../../../MainScreen/MainScreenSlice';
import { clearAddLikeToBuddy, getAllStudentFeedsRequest, getStudentLifeSelector } from './StudentLifeSlice';
import { useEffect, useState } from 'react';
import { getBuddyListSelector } from '../Home/PeopleTab/PeopleTabSlice';
import { useLocation } from 'react-router-dom';
import { getHost } from '../../../utils/hostDataFunction';
import PageLoader from '../../PageLoader';

const { Meta } = Card;

export const StudentLife = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { payload, status: payloadStatus } = useAppSelector(getUniversityResourceSelector);
  const { getAllFeedsStatus, isFetchingAllFeeds, getAllFeedsResponse, addFeedLikeStatus, error } =
    useAppSelector(getStudentLifeSelector);
  const { isFetching, status, buddyListPayload } = useAppSelector(getBuddyListSelector);
  const [originalMainPosts, setOriginalMainPosts] = useState<any>([]);
  const [mainPosts, setMainPosts] = useState<any>([]);
  const [popularPosts, setPopularPosts] = useState([]);

  const [enableWordpress, setEnableWordPress] = useState<boolean>(false);
  const [enableStudentLife, setEnableStudentLife] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const wordPress = searchParams.get('wordpress');
    const studentLife = searchParams.get('studentLife');

    if (wordPress) {
      setEnableWordPress(true);
    }
    if (studentLife) {
      setEnableStudentLife(true);
    }

    let uniId;
    const storedPayload = localStorage.getItem('payload');

    if (storedPayload) {
      const payload = JSON.parse(storedPayload);
      uniId = payload?.domain;
    } else {
      if (payload) {
        uniId = payload?.domain;
        localStorage.setItem('payload', JSON.stringify(payload));
      } else {
        const host = getHost();
        if (host) {
          uniId = host;
        }
      }
    }

    if (uniId) {
      dispatch(getAllStudentFeedsRequest({ uniId }));
    }
  }, []);

  // useEffect(() => {
  //   if (payloadStatus === 'success') {
  //     const uniId: any = payload?.domain;
  //     dispatch(getAllStudentFeedsRequest({ uniId }));
  //   }
  // }, [payloadStatus]);

  useEffect(() => {
    if (addFeedLikeStatus === 'SUCCESS') {
      // const uniId: any = payload?.domain;
      // dispatch(getAllStudentFeedsRequest({ uniId }));
      dispatch(clearAddLikeToBuddy());
    } else if (addFeedLikeStatus === 'FAILED') {
      message.error(error);
      dispatch(clearAddLikeToBuddy());
    }
  }, [addFeedLikeStatus]);

  // useEffect(() => {
  //   if (payload) {
  //     const uniId = payload?.domain;
  //     console.log("=== uniId ===", uniId);

  //     dispatch(getAllStudentFeedsRequest({ uniId }));
  //   }
  // }, []);

  useEffect(() => {
    // console.log("=== getAllFeedsStatus ===", getAllFeedsStatus);

    if (getAllFeedsStatus === 'SUCCESS') {
      // console.log("=== getAllFeedsResponse ===", getAllFeedsResponse);
      handlePopularPosts(getAllFeedsResponse);
      setMainPosts(getAllFeedsResponse);
      setOriginalMainPosts(getAllFeedsResponse);
    }
  }, [getAllFeedsStatus]);

  const handlePopularPosts = (data: any) => {
    const filteredPosts = data.filter((post: any) => post.likeCount > 0);
    const sortByLikes = filteredPosts.sort((a: any, b: any) => b.likeCount - a.likeCount);
    setPopularPosts(sortByLikes);
  };

  const stripHtmlTags = (html: any) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e.target.value === 'string') {
      const searchResults = originalMainPosts.filter((item: any) =>
        stripHtmlTags(item.textBody).toLowerCase().includes(e.target.value.toLowerCase())
      );

      setMainPosts(searchResults.length > 0 ? searchResults : originalMainPosts);
    }
  };

  const handleFilter = (event: any) => {
    let sortedArr: any = [];
    if (event === 'recent') {
      sortedArr = [...mainPosts].sort((a: any, b: any) => {
        const dateA: any = new Date(a?.created);
        const dateB: any = new Date(b?.created);
        return dateB - dateA;
      });
    } else if (event === 'popular') {
      sortedArr = [...mainPosts].sort((a: any, b: any) => b.likeCount - a.likeCount);
    }

    setMainPosts(sortedArr);
  };

  // console.log('addFeedLikeStatus', addFeedLikeStatus);
  // console.log('Error check', error);

  const colSpan = !enableStudentLife && mainPosts.length > 0 ? 18 : 24;

  return (
    <>
      {isFetchingAllFeeds ? (
        <PageLoader />
      ) : (
        <Layout className="studentLife">
          {location?.pathname === '/studentLife' && enableWordpress ? (
            <Row className="mainTitleRowWordpress">
              <Col md={24} lg={24} xs={24}>
                <Typography className="mainTitleWordpress">Check out the latest posts from our Ambassadors</Typography>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Row gutter={20}>
            <Col md={colSpan} lg={colSpan} xs={24}>
              <Row className="studentLifeSearchRow">
                {!enableStudentLife && mainPosts.length > 0 && (
                  <Col md={18} lg={18} xs={24}>
                    <Input placeholder="Search Posts" onChange={handleSearch} size="large" className="slSearch" />
                  </Col>
                )}
                {/* <Col md={6} lg={6} xs={6} className="text-right">
                    <Select
                      defaultValue="recent"
                      onChange={handleFilter}
                      options={[
                        { value: 'recent', label: 'Most Recent' },
                        { value: 'popular', label: 'Most Popular' },
                      ]}
                      className="slFilter"
                    />
                  </Col> */}
              </Row>

              <Row className="studentLifePostCardRow" gutter={[16, 16]}>
                {mainPosts.length === 0 ? (
                  <Col span={24}>
                    <Empty description="No posts available" className="custom-empty" />
                  </Col>
                ) : (
                  mainPosts.map((item: any, key: any) => {
                    return (
                      <Col md={12} lg={12} xs={24} key={key}>
                        <MainPostCard item={item} buddyListPayload={buddyListPayload} />
                      </Col>
                    );
                  })
                )}
              </Row>
            </Col>
            {!enableStudentLife && mainPosts.length > 0 && (
              <Col md={6} lg={6} xs={24}>
                <PopularPostCard popularPosts={popularPosts} />
              </Col>
            )}
          </Row>
        </Layout>
      )}
    </>
  );
};
